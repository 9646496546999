import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [];

export const dictionary = {
		"/": [7],
		"/blog": [~8,[2],[3]],
		"/blog/[slug]": [9,[2],[3]],
		"/demo": [10,[4]],
		"/demo/chats": [11,[4]],
		"/demo/files": [12,[4]],
		"/emergency": [13,[5]],
		"/emergency/chat": [14,[5]],
		"/emergency/drive": [15,[5]],
		"/emergency/login": [16,[5]],
		"/emergency/pake": [17,[5]],
		"/emergency/settings": [18,[5]],
		"/emergency/signup": [19,[5]],
		"/tools": [20,[6]],
		"/tools/identity-generator": [21,[6]],
		"/tools/password-generator": [22,[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';